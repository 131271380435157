<template>
  <v-app>
    <v-main>
      <v-container>
        <div class="loginHeader">
          <span>Login Verification</span>
        </div>
        <div class="loginContent">
          <v-text-field
              label="One time password"
              hide-details="auto"
          ></v-text-field>
          <div class="loginFooter">

            <a class="sendAgain btn" href="#">Send Again</a>
            <v-btn
                elevation="2"
                color="primary"
            >Login</v-btn>
          </div>
        </div>
        <span class="copyRights">&#169;  2021 	&#8901; Warp It</span>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LoginVerification"
}
</script>

<style scoped>
.loginHeader {background: #0F3542; padding: 12px 20px;}
.loginHeader span {color: #ffffff; font-size: 18px; line-height: 21.6px;}
.loginFooter {margin-top: 30px;}
</style>