<template>
  <v-app>
    <v-main>
      <v-container>
        <div class="loginHeader">
          <a href="#"><img :src="require('../assets/warpitLogoWhite.svg')"/></a>
        </div>
        <div class="loginContent">
          <ul>
            <li><strong>Uniqa AG</strong></li>
            <li>Warp it - Market and Opinion Research Agencies Package</li>
            <li>Version: 12.5.7</li>
          </ul>
          <v-text-field
              label="Email Address"
              hide-details="auto"
          ></v-text-field>
          <v-text-field label="Password"></v-text-field>
          <div class="loginFooter">
            <a class="forgotPass btn" href="#">Forgot your Password?</a>
            <v-btn
                elevation="2"
                color="primary"
            >Login</v-btn>
          </div>
        </div>
        <span class="copyRights">&#169;  2021 	&#8901; Warp It</span>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login"
}
</script>

<style scoped>
.loginHeader {
  height: 250px;
  background: #0F3542 url('../assets/loginBackground.svg') no-repeat center right;
  background-size: auto 250px;
  padding: 30px 20px;
}

@media only screen and (max-width: 525px) {
  .loginHeader {
    background: #0F3542 url('../assets/loginBackground.svg') no-repeat top right;
    background-size: auto 180px;
  }
}
</style>